import './FamilyLink.css';
import {useNavigate} from "react-router-dom";

import myImage from '../../assets/fl-icon-aquilone.svg';

function FamilyLink() {
    const navigate = useNavigate();

    function navigateHandler(selectedTab) {
        navigate('/family-link' + selectedTab)
    }

    return(
        <div className={"main-container"}>
            <div className="top-container">
                <img src={myImage} alt={'icon'} className={'svg-aquilone'}/>
                <h1 className={'main-h1'}>Aiuta la tua famiglia a navigare in modo più sicuro</h1>
                <h2 style={{fontWeight: 'normal'}}>Con Family Link, decidi ciò che è meglio per la tua famiglia.
                    Gli strumenti intuitivi ti consentono di conoscere come tuo
                    figlio trascorre il tempo sul suo dispositivo, gestire le
                    impostazioni della privacy e tanto altro.
                </h2>
                <h2 style={{fontWeight: "bold"}}>
                    Seleziona una delle schede per maggiori informazioni.
                </h2>

            </div>

            <div className={"tabs-container"}>
                <div
                    className={'tab tab-1'}
                    style={{backgroundColor: '#3f86f8'}}
                    onClick={() => navigateHandler('/rules')}
                >
                    <div className={'tab-txt'}>
                        <h2>Crea regole di base<br/>digitali</h2>
                    </div>

                </div>

                <div
                    className={'tab tab-2'}
                    style={{backgroundColor: '#fbbd02'}}
                    onClick={() => navigateHandler('/privacy')}
                >
                    <div className={'tab-txt'}>
                        <h2>Proteggi e gestisci l'account di tuo figlio</h2>
                    </div>
                </div>

                <div
                    className={'tab'}
                    style={{backgroundColor: '#32a754'}}
                    onClick={() => navigateHandler('/contact')}
                >
                    <div className={'tab-txt'}>
                        <h2 className={'tab-txt-h2'}>Resta in contatto<br/>ovunque ti trovi</h2>
                    </div>
                </div>
            </div>
            <div className={'img-free-container'}>

            </div>

        </div>

    )
}

export default FamilyLink;