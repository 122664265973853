import {useNavigate} from "react-router-dom";
import projectStyle from "./Projects.module.css";

import qrCode from "../../assets/qr-code.png"
import homeIcon from "../../assets/home-icon.svg";

function Projects() {
    const navigate = useNavigate();

    function navigateHandler() {
        navigate('/vivi-internet')
    }

    return (
        <div className={projectStyle.mainContainer}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />

            <div className={projectStyle.titleContainer}>
                <h1 style={{fontWeight: 'bold'}} className={projectStyle.pageTitle}>
                    Interland: <br/>
                    avventure digitali
                </h1>
                <h2 style={{fontWeight: 'bold'}} className={projectStyle.pageTitle}>In viaggio col nonno alla scoperta
                    del Web</h2>
                <p className={projectStyle.pageTitle}>
                    Questa è la storia di un nonno che, proprio il giorno del suo ottantesimo compleanno, parte alla
                    scoperta del Web insieme a Matilde e Davide, i suoi nipoti. Durante il viaggio i nostri protagonisti
                    impareranno a conoscere tutti gli strumenti e le risorse utili per navigare in modo responsabile.
                    Inizia anche tu questa straordinaria avventura insieme a tutta la famiglia, completa le attività e
                    scopri tutti i segreti per vivere Internet, al meglio.
                </p>

                <h3 style={{margin: 'auto', marginTop: '3vh'}} className={projectStyle.pageTitle}>SCARICA IL LIBRO</h3>
                <img src={qrCode} style={{margin: 'auto'}} className={projectStyle.qrCode} alt={''}/>
            </div>
            <div className={projectStyle.videoContainer}>
                <iframe
                    src="https://player.vimeo.com/video/916667421?h=4147d574ec&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                    style={{position: "relative", width: "80%", height: "100%", margin:'auto'}}
                    title="Interland: avventure digitali">
                </iframe>
                {/*<video width={'720'} height={'480'} controls={true} className={projectStyle.videoPlayer}>
                    <source src={videoViva} type={"video/mp4"}/>
                </video>*/}
            </div>
            <div className={'img-free-container'}>

            </div>
        </div>
    )
}

export default Projects;