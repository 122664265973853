import {useNavigate} from "react-router-dom";
import sharedStyle from "./YoutubeShared.module.css";
import homeIcon from "../../assets/home-icon.svg";
import mainImg from "../../assets/youtube-kids-images/limite-tempo.png";

function LimiteTempo() {
    const navigate = useNavigate();

    function navigateHandler() {
        navigate('/youtube-kids')
    }
    return (
        <div className={sharedStyle.mainContainer}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />
            <div className={sharedStyle.imgContainer}>
                <img src={mainImg} alt={''} className={sharedStyle.imgMain}/>
            </div>
            <div className={sharedStyle.infoContainer}>
                <div className={sharedStyle.textContainer}>
                    <h1
                        className={sharedStyle.textTitle}
                    >
                        Limitare il tempo di utilizzo.
                    </h1>
                    <h3>
                        Imposta un limite di tempo per la visione di contenuti
                        da parte dei tuoi figli e incoraggiali a cimentarsi in attività pratiche.
                    </h3>
                </div>
            </div>
            <div className={'img-free-container'}>
            </div>
        </div>
    )
}

export default LimiteTempo;