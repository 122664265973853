import {useNavigate} from "react-router-dom";
import sharedStyle from "./YoutubeShared.module.css";
import homeIcon from "../../assets/home-icon.svg";
import mainImage from "../../assets/youtube-kids-images/contenuti-approvati.png";

function ContenutiApprovati() {
    const navigate = useNavigate();

    function navigateHandler() {
        navigate('/youtube-kids')
    }
    return (
        <div className={sharedStyle.mainContainer}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />
            <div className={sharedStyle.infoContainer}>
                <div className={sharedStyle.textContainerLeft}>
                    <h1
                        className={sharedStyle.textTitle}
                    >
                        Approvare ciò che possono guardare i bambini.
                    </h1>
                    <h3>
                        Vuoi avere il pieno controllo su ciò che stanno guardando
                        i tuoi figli? Seleziona la modalità "Solo contenuti approvati"
                        e i tuoi figli potranno guardare solo video, canali e/o
                        raccolte che hai selezionato personalmente e approvato.
                        In questa modalità, i bambini non potranno cercare video.
                    </h3>
                </div>
            </div>
            <div className={sharedStyle.imgContainer}>
                <img src={mainImage} alt={''} className={sharedStyle.imgMain}/>
            </div>
            <div className={'img-free-container'}>

            </div>
        </div>
    )
}

export default ContenutiApprovati;