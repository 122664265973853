import infoCardStyle from "./InfoCard.module.css"

function InfoCard(props) {

    return (
        <div className={infoCardStyle.cardContainer}>
            <div className={infoCardStyle.cardHeader}>
                <img src={props.icon} alt={'icon'} className={infoCardStyle.cardIcon}/>
            </div>
            <p>{props.content}</p>
        </div>
    )
}

export default InfoCard;