import myImage from "../../assets/kid-tel-1.jpeg";
import homeIcon from "../../assets/home-icon.svg";
import phoneImage from "../../assets/phone-2.png"
import phoneImage2 from "../../assets/phone-2.2.png"
import privacyStyle from "./Privacy.module.css"

import {useNavigate} from "react-router-dom";
import {useState} from "react";
import arrowRightIcon from "../../assets/google-icons/arrow-right.svg";
import rulesStyle from "./Rules.module.css";

function Privacy() {
    const navigate = useNavigate();
    const [section, setSection] = useState('section1')
    const [isRotated, setRotated] = useState(false)

    function navigateHandler() {
        navigate('/family-link')
    }
    function sectionChangeHandler() {
        if(section === 'section1') {
            setSection('section2')
            setRotated(true)
        } else {
            setSection('section1')
            setRotated(false)
        }
    }
    return (
        <div className={privacyStyle.mainPanel}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home-white'}
                onClick={() => navigateHandler()}
            />

            <div className={privacyStyle.imgContainer}>
                <img src={myImage} alt={'icon'} className={privacyStyle.imgBig}/>
            </div>
            <div className={privacyStyle.infoContainer}>
                <div className={privacyStyle.txtContainer}>
                    <h1>Proteggi e gestisci l'account di tuo figlio</h1>
                    {section === 'section1' &&
                        <div className={privacyStyle.section}>
                            <h3>Proteggi la sua privacy</h3>
                            <p>
                                La gestione delle autorizzazioni in Family Link
                                ti consente di fare le scelte giuste riguardo ai
                                dati di tuo figlio. Puoi visualizzare e gestire
                                le autorizzazioni per i siti web e le estensioni
                                a cui accede sia con Chrome sia con le app che ha
                                scaricato sul suo dispositivo.
                            </p>
                        </div>
                    }
                    {section === 'section2' &&
                        <div className={privacyStyle.section}>
                            <h3>Proteggi il suo account</h3>
                            <p>
                                Family Link ti permette di accedere per gestire
                                l'account e le impostazioni dei dati di tuo figlio.
                                Come genitore, puoi aiutare tuo figlio a cambiare o
                                reimpostare la password, se l'ha dimenticata, a
                                modificare le sue informazioni personali o persino
                                eliminare il suo account, se pensi che sia necessario.
                            </p>
                        </div>
                    }
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <img
                            src={arrowRightIcon}
                            alt={'icon'}
                            className={rulesStyle.arrowIcon}
                            style={{
                                rotate: isRotated ? "180deg" : "0deg"
                            }}
                            onClick={sectionChangeHandler}
                        />
                    </div>
                </div>
            </div>
            {section === 'section1' &&
                <img src={phoneImage} alt={'icon'} className={privacyStyle.imgPhone2}/>
            }
            {section === 'section2' &&
                <img src={phoneImage2} alt={'icon'} className={privacyStyle.imgPhone2}/>
            }
            <div className={'img-free-container'} style={{overflow:'hidden'}}>
                <svg className={'svg-line'} width="99vw" height="99vh">
                    <line fill="#3f86f8" stroke="#3f86f8" strokeWidth="10" x1="85vw" y1="35vh"
                          x2="100vw" y2="35vh"/>
                    <line fill="#fbbd02" stroke="#fbbd02" strokeWidth="10" x1="42vw" y1="0vh"
                          x2="42vw" y2="100vh"/>
                    <line fill="#32a754" stroke="#32a754" strokeWidth="10" x1="20vw" y1="86vh"
                          x2="38vw" y2="86vh"/>
                </svg>
            </div>
        </div>
    )
}

export default Privacy;