import {useNavigate} from "react-router-dom";
import principiStyle from "./Principi.module.css";
import partnerStyle from "./Partner.module.css";
import InfoCard from "../../components/InfoCard";

import { PARTNERS_DATA } from "./partnerData.js"
import homeIcon from "../../assets/home-icon.svg";

function Partner() {
    const navigate = useNavigate();

    function navigateHandler() {
        navigate('/vivi-internet')
    }

    return (
        <div className={partnerStyle.mainContainer}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />

            <div className={principiStyle.titleContainer}>
                <h1 style={{fontWeight: 'bold'}} className={principiStyle.pageTitle}>Esperti di educazione digitale</h1>
                <h2 style={{fontWeight: 'bold'}} className={principiStyle.pageTitle}>I NOSTRI PARTNER</h2>
            </div>
            <div className={partnerStyle.sideContainer}>
                <div className={partnerStyle.sideText}>
                    <h2 style={{fontWeight: 'normal', fontSize: '2vw'}}>
                        Abbiamo collaborato con esperti in materia di educazione digitale per fornire gli strumenti
                        più indicati a famiglie ed educatori
                    </h2>
                </div>
                <div className={partnerStyle.cardContainer}>
                    <InfoCard
                        icon={PARTNERS_DATA[0].icon}
                        content={PARTNERS_DATA[0].content}
                    >
                    </InfoCard>
                    <InfoCard
                        icon={PARTNERS_DATA[1].icon}
                        content={PARTNERS_DATA[1].content}
                    >
                    </InfoCard>
                    <InfoCard
                        icon={PARTNERS_DATA[2].icon}
                        content={PARTNERS_DATA[2].content}
                    >
                    </InfoCard>
                    <InfoCard
                        icon={PARTNERS_DATA[3].icon}
                        content={PARTNERS_DATA[3].content}
                    >
                    </InfoCard>
                    <InfoCard
                        icon={PARTNERS_DATA[4].icon}
                        content={PARTNERS_DATA[4].content}
                    >
                    </InfoCard>
                </div>
            </div>
            <div className={'img-free-container'}>

            </div>
        </div>
    )
}

export default Partner;