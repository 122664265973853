import {useNavigate} from "react-router-dom";
import CollapseCard from "../../components/CollapseCard";

import principiStyle from "./Principi.module.css";
import { PRINCIPI_BASE } from "./pricipiData.js"

import homeIcon from "../../assets/home-icon.svg";
import chevronLeft from "../../assets/google-icons/chevron-left.svg";
import chevronRight from "../../assets/google-icons/chevron-right.svg";
import {useState} from "react";

function Principi() {
    const navigate = useNavigate();
    const [slideNum, setSlideNum] = useState(0)

    function navigateHandler() {
        navigate('/vivi-internet')
    }

    function handlePreviousSlide() {
        if(slideNum === 0) {
            setSlideNum(4);
        } else {
            setSlideNum(slideNum-1);
        }
    }

    function handleNextSlide() {
        if(slideNum === 4) {
            setSlideNum(0);
        } else {
            setSlideNum(slideNum+1);
        }
    }

    return(
        <div className={principiStyle.mainContainer}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />

            <div className={principiStyle.titleContainer}>
                <h1 style={{fontWeight: 'bold'}} className={principiStyle.pageTitle}>Il codice per vivere Internet al
                    meglio</h1>
                <h2 style={{fontWeight: 'bold'}} className={principiStyle.pageTitle}>I PRINCIPI DI BASE</h2>
            </div>

            <div className={principiStyle.cardsContainer}>
                <img src={chevronLeft} alt={'icon'}
                     style={{width: '8vw', height: '8vh', margin: 'auto', marginLeft: '0', marginRight: '0'}}
                     onClick={handlePreviousSlide}
                />
                <div >
                {slideNum === 0 &&
                    <CollapseCard
                        className={principiStyle.card}
                        title={PRINCIPI_BASE[0].title}
                        content={PRINCIPI_BASE[0].content}
                        icon={PRINCIPI_BASE[0].icon}
                        list={PRINCIPI_BASE[0].list}
                        elements={PRINCIPI_BASE[0].elements}
                    >
                    </CollapseCard>
                }

                {slideNum === 1 &&
                    <CollapseCard
                        className={principiStyle.card}
                        title={PRINCIPI_BASE[1].title}
                        content={PRINCIPI_BASE[1].content}
                        icon={PRINCIPI_BASE[1].icon}
                        list={PRINCIPI_BASE[1].list}
                        elements={PRINCIPI_BASE[1].elements}
                    >
                    </CollapseCard>
                }

                {slideNum === 2 &&
                    <CollapseCard
                        title={PRINCIPI_BASE[2].title}
                        content={PRINCIPI_BASE[2].content}
                        icon={PRINCIPI_BASE[2].icon}
                        list={PRINCIPI_BASE[2].list}
                        elements={PRINCIPI_BASE[2].elements}
                    >
                    </CollapseCard>
                }

                {slideNum === 3 &&
                    <CollapseCard
                        title={PRINCIPI_BASE[3].title}
                        content={PRINCIPI_BASE[3].content}
                        icon={PRINCIPI_BASE[3].icon}
                        list={PRINCIPI_BASE[3].list}
                        elements={PRINCIPI_BASE[3].elements}
                    >
                    </CollapseCard>
                }

                {slideNum === 4 &&
                    <CollapseCard
                        title={PRINCIPI_BASE[4].title}
                        content={PRINCIPI_BASE[4].content}
                        icon={PRINCIPI_BASE[4].icon}
                        list={PRINCIPI_BASE[4].list}
                        elements={PRINCIPI_BASE[4].elements}
                    >
                    </CollapseCard>
                }
                </div>
                <img src={chevronRight} alt={'icon'}
                     style={{width: '8vw', height: '8vh', margin: 'auto', marginLeft: '0', marginRight: '0'}}
                     onClick={handleNextSlide}
                />
            </div>
            <div className={'img-free-container'}>

            </div>
        </div>
    )
}

export default Principi;