import sharedStyle from "./YoutubeShared.module.css"
import profiliImg from "../../assets/youtube-kids-images/profili-unici.png"
import {useNavigate} from "react-router-dom";
import homeIcon from "../../assets/home-icon.svg";

function ProfiliUnici() {
    const navigate = useNavigate();

    function navigateHandler() {
        navigate('/youtube-kids')
    }
    return (
        <div className={sharedStyle.mainContainer}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />
            <div className={sharedStyle.imgContainer}>
                <img src={profiliImg} alt={''} className={sharedStyle.imgMain}/>
            </div>
            <div className={sharedStyle.infoContainer}>
                <div className={sharedStyle.textContainer}>
                    <h1
                        className={sharedStyle.textTitle}
                    >
                        Creare profili individuali unici, come i tuoi figli.
                    </h1>
                    <h3>
                        Accedi e crea fino a otto profili bambino, ognuno con i suoi contenuti, impostazioni e consigli
                        di video.
                    </h3>
                </div>
            </div>
            <div className={'img-free-container'}>

            </div>
        </div>
    )
}

export default ProfiliUnici;