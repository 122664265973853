import kidStyle from "./YoutubeKids.module.css"
import youtubeLogo from "../../assets/logos/logo-youtube-kids.svg";
import {useNavigate} from "react-router-dom";

function YoutubeKids() {
    const navigate = useNavigate();

    function navigateHandler(selectedTab) {
        navigate('/youtube-kids' + selectedTab)
    }

    return (
        <div className={kidStyle.mainContainer}>
            <div className={kidStyle.infoContainer}>
                <img src={youtubeLogo} alt={''} className={kidStyle.kidsLogo}/>
                <div className={kidStyle.txtContainer}>
                    <h1 className={kidStyle.mainText}>Un'esperienza online più sicura per i bambini.</h1>
                    <h2 style={{fontWeight:'normal'}}>
                        L'app YouTube Kids è stata progettata per offrire ai bambini un'esperienza più semplice e
                        sicura, in cui possano scoprire nuovi interessi esplorando i video online.
                    </h2>
                    <h2 style={{fontWeight:'bold'}}>
                        Seleziona una delle schede per maggiori informazioni.
                    </h2>
                </div>
            </div>

            <div className={kidStyle.tabsContainer}>
                <div
                    className={kidStyle.tab}
                    onClick={() => navigateHandler('/profili-individuali-unici')}
                >
                    <h2 className={kidStyle.tabText} >Profili individuali unici</h2>
                </div>
                <div
                    className={kidStyle.tab}
                    onClick={() => navigateHandler('/contenuti-approvati')}
                >
                    <h2 className={kidStyle.tabText} >Contenuti approvati</h2>
                </div>
                <div
                    className={kidStyle.tab}
                    onClick={() => navigateHandler('/contenuti-per-eta')}
                >
                    <h2 className={kidStyle.tabText} >Contenuti per età</h2>
                </div>
                <div
                    className={kidStyle.tab}
                    onClick={() => navigateHandler('/limite-tempo')}
                >
                    <h2 className={kidStyle.tabText} >Limite tempo</h2>
                </div>
                <div
                    className={kidStyle.tab}
                    onClick={() => navigateHandler('/blocco-video')}
                >
                    <h2 className={kidStyle.tabText} >Blocco video</h2>
                </div>
                <div
                    className={kidStyle.tab}
                    onClick={() => navigateHandler('/segnalazioni')}
                >
                    <h2 className={kidStyle.tabText} >Segnalazioni</h2>
                </div>
            </div>

        </div>
    )
}

export default YoutubeKids;