import envelopeIcon from "../../assets/google-icons/envelope.svg";
import dangerIcon from "../../assets/google-icons/alert.svg";
import lockIcon from "../../assets/google-icons/padlock.svg";
import kindIcon from "../../assets/google-icons/kind.svg";
import bubbleIcon from "../../assets/google-icons/bubble.svg";


export const PRINCIPI_BASE = [
    {
        title: 'Utilizza la tecnologia con buonsenso',
        content: 'Alcune cattive abitudini, relative ' +
            'al modo in cui utilizzi i dispositivi digitali ' +
            'e il Web, potrebbero comportare danni alla tua ' +
            'reputazione o avere conseguenze permanenti sulla ' +
            'tua vita e quella di altri. È fondamentale ' +
            'comprendere che un uso equilibrato e intelligente ' +
            'dei dispositivi, dei social e più in generale di ' +
            'Internet può rappresentare un\'opportunità di ' +
            'crescita e di apprendimento.',
        icon: envelopeIcon,
        list: 'Comunica in modo responsabile e utilizza la tecnologia in modo equilibrato',
        elements: [
            'Realtà e Internet non sono due mondi separati: quello che fai online ha lo stesso peso di quello che fai offline.',
            'Instaura un rapporto equilibrato con la tecnologia, assicurati che ti migliori la vita e non ti distragga troppo o involontariamente da ciò che conta veramente per te.',
            'Rifletti sul ruolo che la tecnologia ha nella tua vita, iniziando a seguire alcune buone prassi: ad esempio, disattiva le notifiche sul tuo telefono, ricarica lo smartphone lontano dal letto, scegli la modalità aereo quando sei a tavola o in compagnia.',
            'Verifica che le attività svolte dai più piccoli sui dispositivi siano appropriate per lo sviluppo, migliorino l\'umore e siano educative.'
        ]
    },
    {
        title: 'Impara a distinguere il vero dal falso',
        content: 'È importante aiutare i bambini e i ragazzi a comprendere che le persone e le situazioni online non sono sempre ciò che sembrano. Quando si parla di sicurezza online, saper distinguere il vero dal falso è molto importante.',
        icon: dangerIcon,
        list: 'Assicurati che sia vero e riconosci gli elementi sospetti',
        elements: [
            'Quando leggi una notizia, raccogli informazioni sulla fonte e assicurati che sia attendibile. Puoi cercare il suo nome online ed effettuare un controllo incrociato per verificare se anche altre testate hanno riportato la notizia.',
            'Sii un abile detective della rete: approfondisci sempre ciò che leggi, non fermarti al titolo e smaschera i contenuti ingannevoli.',
            'Continua a farti domande. Le informazioni di qualità sono dei mattoncini solidi e resistenti, il passo successivo è quello di utilizzarle per costruire la tua opinione.',
            'Se le affermazioni che promettono di "vincere" qualcosa o di ottenerlo "gratis" sembrano troppo belle per essere vere, molto probabilmente non lo sono.',
            'In uno scambio onesto, dove gli interlocutori non hanno secondi fini se non quello di comunicare, non dovrebbe essere chiesto di divulgare informazioni personali di alcun tipo.',
            'Rifletti sempre prima di agire online. Fai attenzione al phishing, ovvero al tentativo di furto di dati di accesso o dettagli del tuo account tramite email, chat o altre comunicazioni online che sembrano provenire da un contatto fidato.'
        ]
    },
    {
        title: 'Custodisci le tue informazioni personali',
        content: 'La privacy e la sicurezza sono tanto importanti online quanto lo sono offline: salvaguardare le informazioni più preziose aiuta i più giovani a evitare di danneggiare reputazione e rapporti personali.',
        icon: lockIcon,
        list: 'Crea una password efficace',
        elements: [
            'Fai in modo che sia facile da ricordare, ma evita di usare informazioni personali, come nomi o compleanni.',
            'La lunghezza delle password è importante: più è lunga la tua password, più difficile sarà indovinarla.',
            'Usa la verifica in due passaggi (ricevendo un codice di verifica sul tuo telefono).',
            'Non usare la stessa password su siti diversi.'
        ]
    },
    {
        title: 'Diffondi la gentilezza',
        content: 'Internet è un potente amplificatore: può essere usato per diffondere messaggi sia positivi che negativi. Bambini e ragazzi possono dare il meglio di sé, innescando comportamenti positivi in grado di contrastare gli atteggiamenti di prepotenza e di incitamento all\'odio.',
        icon: kindIcon,
        list: 'Dai il buon esempio',
        elements: [
            'Usa Internet per amplificare messaggi positivi.',
            'Ferma il diffondersi di messaggi falsi o dannosi evitando di condividerli con gli altri.',
            'Rispetta chi è diverso da te.',
            'Verifica di possedere tutte le informazioni necessarie, prima di esprimere un giudizio negativo rispetto a fatti, persone o gruppi di persone.',
            'Ricorda che quello che più conta è trattare gli altri come vorresti che trattassero te.',
            'Segnala i comportamenti inappropriati online.',
            'Cerca di sostenere chi subisce episodi di bullismo.',
            'Incoraggia i bambini e i ragazzi a prendere posizione contro il bullismo online e a segnalarne gli episodi.',
            'Rispondi all’odio in rete con una prospettiva costruttiva e con un atteggiamento positivo e aperto all\'ascolto.'
        ]
    },
    {
        title: 'Nel dubbio, parlane',
        content: 'Quando i bambini e i ragazzi leggono o vedono qualcosa online che suscita in loro dubbi o interrogativi, dovrebbero sapere di poterne parlare tranquillamente con un adulto. Gli adulti, da parte loro, possono aiutarli incoraggiando una comunicazione aperta, a casa e in classe.',
        icon: bubbleIcon,
        list: 'Promuovi un atteggiamento aperto e di dialogo',
        elements: [
            'Chiarisci le regole di utilizzo del Web, parla ai più giovani delle opportunità che la rete può offrire e anche dei modi migliori di usarla senza rischi.',
            'Cerca di stabilire un dialogo, lascia loro lo spazio per fare domande. Pur essendo nativi digitali, possono avere dubbi e non sapere con chi confrontarsi.',
            'Coinvolgi nella conversazione anche altri adulti di riferimento, come genitori, insegnanti, parenti e amici.'
        ]
    },
]