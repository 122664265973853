import {useNavigate} from "react-router-dom";
import sharedStyle from "./YoutubeShared.module.css";
import homeIcon from "../../assets/home-icon.svg";
import mainImg from "../../assets/youtube-kids-images/contenuti-per-eta.png";

function ContenutiEta() {
    const navigate = useNavigate();

    function navigateHandler() {
        navigate('/youtube-kids')
    }

    return (
        <div className={sharedStyle.mainContainer}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />
            <div className={sharedStyle.infoContainer}>
                <div className={sharedStyle.textContainerLeft}>
                    <h1
                        className={sharedStyle.textTitle}
                    >
                        Seleziona i livelli dei contenuti in base all'età di tuo figlio
                    </h1>
                    <h3>
                        Ci adoperiamo in ogni modo per far sì che i video disponibili
                        su YouTube Kids siano adatti alle famiglie. A tal fine, utilizziamo
                        una combinazione di filtri automatici sviluppati dai nostri team
                        tecnici, revisione manuale fatta da persone e feedback ricevuti
                        dai genitori con lo scopo di proteggere i nostri utenti più giovani
                        durante la loro esperienza online. Purtroppo, però, nessun sistema
                        è perfetto e potrebbero comunque essere visualizzati video inappropriati.
                        Per questo, lavoriamo incessantemente per migliorare le nostre misure
                        di sicurezza e offrire più funzionalità per aiutare i genitori a creare
                        l'esperienza giusta per le loro famiglie.
                    </h3>
                </div>
            </div>
            <div className={sharedStyle.imgContainer}>
                <img src={mainImg} alt={''} className={sharedStyle.imgMain}/>
            </div>
            <div className={'img-free-container'}>

            </div>
        </div>
    )
}

export default ContenutiEta;