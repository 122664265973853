import collapseCardStyle from './CollapseCard.module.css'

// import {useState} from "react";
// import expandIcon from "../assets/google-icons/expand.svg";

function CollapseCard(props) {
    // const [toggle, setToggle] = useState(false);

    return (
        <div>
            <div className={collapseCardStyle.collapsibleCard}>
                <div
                    className={collapseCardStyle.header}
                >
                    <img src={props.icon} alt={'icon'} className={collapseCardStyle.cardIcon}/>
                    <h1 className={collapseCardStyle.cardTitle} style={{fontWeight: 'bold'}}>{props.title}</h1>
                    {/*<img
                        src={expandIcon}
                        alt={'icon'}
                        className={collapseCardStyle.expandIcon}
                        style={{
                            rotate: toggle ? "180deg" : "0deg"
                        }}
                    />*/}
                </div>
                <div
                    className={collapseCardStyle.content}
                >
                    <p className={collapseCardStyle.cardContent}>
                        {props.content}
                    </p>
                </div>
                <div
                    className={collapseCardStyle.collapseContent}
                    style={{
                        maxHeight: "50vh" // per collapsed : toggle ? 50vh : 0px
                    }}
                >
                    <p className={collapseCardStyle.cardContent} style={{fontWeight: 'bold'}}>
                        {props.list}
                    </p>
                    <ul>
                        {
                            props.elements.map(el => <li><p>{el}</p></li>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CollapseCard;