import {useNavigate} from "react-router-dom";
import sharedStyle from "./YoutubeShared.module.css";
import homeIcon from "../../assets/home-icon.svg";
import mainImg from "../../assets/youtube-kids-images/segnalazioni.png";

function Segnalazioni() {
    const navigate = useNavigate();

    function navigateHandler() {
        navigate('/youtube-kids')
    }
    return (
        <div className={sharedStyle.mainContainer}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />
            <div className={sharedStyle.imgContainer}>
                <img src={mainImg} alt={''} className={sharedStyle.imgMain}/>
            </div>
            <div className={sharedStyle.infoContainer}>
                <div className={sharedStyle.textContainer}>
                    <h1
                        className={sharedStyle.textTitle}
                    >
                        Collaborazione per mantenerli al sicuro.
                    </h1>
                    <h3>
                        Puoi sempre avvisarci quando rilevi contenuti
                        inappropriati segnalando un video per sottoporlo a
                        revisione. I video segnalati vengono rivisti 24 al giorno, sette giorni su sette.
                    </h3>
                </div>
            </div>
            <div className={'img-free-container'}>

            </div>
        </div>
    )
}

export default Segnalazioni;