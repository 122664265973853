import './fonts.css';
import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import FamilyLink from "./pages/family-link/FamilyLink";
import ViviInternet from "./pages/vivi-internet/ViviInternet";
import Rules from "./pages/family-link/Rules";
import Privacy from "./pages/family-link/Privacy";
import Contact from "./pages/family-link/Contact";
import Principi from "./pages/vivi-internet/Principi";
import Partner from "./pages/vivi-internet/Partner";
import Projects from "./pages/vivi-internet/Projects";
import YoutubeKids from "./pages/youtube-kids/YoutubeKids";
import ProfiliUnici from "./pages/youtube-kids/ProfiliUnici";
import ContenutiApprovati from "./pages/youtube-kids/ContenutiApprovati";
import ContenutiEta from "./pages/youtube-kids/ContenutiEta";
import LimiteTempo from "./pages/youtube-kids/LimiteTempo";
import BloccoVideo from "./pages/youtube-kids/BloccoVideo";
import Segnalazioni from "./pages/youtube-kids/Segnalazioni";

const router = createBrowserRouter([
    { path: '/youtube-kids', element: <YoutubeKids/>},
    { path: '/youtube-kids/profili-individuali-unici', element: <ProfiliUnici/>},
    { path: '/youtube-kids/contenuti-approvati', element: <ContenutiApprovati/>},
    { path: '/youtube-kids/contenuti-per-eta', element: <ContenutiEta/>},
    { path: '/youtube-kids/limite-tempo', element: <LimiteTempo/>},
    { path: '/youtube-kids/blocco-video', element: <BloccoVideo/>},
    { path: '/youtube-kids/segnalazioni', element: <Segnalazioni/>},

    { path: '/vivi-internet', element: <ViviInternet/>},
    { path: '/vivi-internet/basic-principles', element: <Principi/>},
    { path: '/vivi-internet/partner', element: <Partner/>},
    { path: '/vivi-internet/projects', element: <Projects/>},

    { path: '/family-link', element: <FamilyLink/>}, // Main Route
    { path: '/family-link/rules', element: <Rules/>},
    { path: '/family-link/privacy', element: <Privacy/>},
    { path: '/family-link/contact', element: <Contact/>},

    { path: '/', element: <FamilyLink/>}
])

function App() {
    return <RouterProvider router={router} />;
}

export default App;
