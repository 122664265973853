import './Rules.css'
import myImage from "../../assets/kid-tel.jpeg";
import homeIcon from "../../assets/home-icon.svg";
import arrowRightIcon from "../../assets/google-icons/arrow-right.svg"
import phoneImage from "../../assets/phone-1.png"
import phoneImage2 from "../../assets/phone-1.2.png"

import rulesStyle from "./Rules.module.css"

import {useNavigate} from "react-router-dom";
import {useState} from "react";
function Rules() {
    const navigate = useNavigate();
    const [section, setSection] = useState('section1')
    const [isRotated, setRotated] = useState(false)
    // const section = 'section1';

    function navigateHandler() {
        navigate('/family-link')
    }

    function sectionChangeHandler() {
        if(section === 'section1') {
            setSection('section2')
            setRotated(true)
        } else {
            setSection('section1')
            setRotated(false)
        }
    }

    return (
        <div className={rulesStyle.mainPanel}>

            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={navigateHandler}
            />


            <div className={rulesStyle.infoContainer}>
                <div className={ rulesStyle.txtContainer}>
                    <div>
                        <h1>Crea regole di base digitali</h1>
                    </div>
                    {section === 'section1' &&
                        <div className={rulesStyle.section}>
                            <h3>Imposta limiti per il tempo di utilizzo</h3>
                            <p>
                                Scegli il tempo di utilizzo più adatto a tuo figlio.
                                Con Family Link puoi impostare sul dispositivo di tuo
                                figlio l'ora di andare a dormire e limiti di tempo per
                                le app, così puoi aiutarlo a trovare il giusto equilibrio.
                            </p>
                        </div>
                    }
                    {section === 'section2' &&
                        <div className={rulesStyle.section}>
                            <h3>Guida tuo figlio verso contenuti adatti alla sua età</h3>
                            <p>
                                Approva o blocca le app che tuo figlio vuole scaricare.
                                Con Family Link puoi anche scegliere l'esperienza giusta
                                per tuo figlio su YouTube: un'esperienza con supervisione
                                su YouTube o YouTube Kids.
                            </p>
                        </div>
                    }
                    <div style={{display:'flex', justifyContent:'left'}}>
                        <img
                            src={arrowRightIcon}
                            alt={'icon'}
                            className={rulesStyle.arrowIcon}
                            style={{
                                rotate: isRotated ? "180deg" : "0deg"
                            }}
                            onClick={sectionChangeHandler}
                        />
                    </div>
                </div>
            </div>

            <div className={rulesStyle.imgContainer}>
                <img src={myImage} alt={'icon'} className={rulesStyle.imgBig}/>
            </div>
            { section ==='section1' &&
                <img src={phoneImage} alt={'icon'} className={rulesStyle.imgPhone1}/>
            }
            { section ==='section2' &&
                <img src={phoneImage2} alt={'icon'} className={rulesStyle.imgPhone12}/>
            }
            <div className={'img-free-container'} style={{overflow:'hidden'}}>
                <svg className={'svg-line'} width="99vw" height="99vh">
                    <line fill="#3f86f8" stroke="#3f86f8" strokeWidth="10" x1="0vw" y1="35vh"
                          x2="12vw" y2="35vh"/>
                    <line fill="#fbbd02" stroke="#fbbd02" strokeWidth="10" x1="58vw" y1="0vh"
                          x2="58vw" y2="100vh"/>
                    <line fill="#32a754" stroke="#32a754" strokeWidth="10" x1="62vw" y1="85vh"
                          x2="85vw" y2="85vh"/>
                </svg>
            </div>
        </div>

    )
}

export default Rules;