import  viviInternetStyle from './ViviInternet.module.css';
import {useNavigate} from "react-router-dom";

function ViviInternet() {
    const navigate = useNavigate();

    function navigateHandler(selectedTab) {
        navigate('/vivi-internet' + selectedTab)
    }
    return(
        <div className={viviInternetStyle.mainContainer}>
            <div className={viviInternetStyle.subMainContainer}>
                <div className={viviInternetStyle.infoContainer}>
                    <h2 className={viviInternetStyle.textBold}>Vivi Internet, al meglio.</h2>
                    <h1 className={viviInternetStyle.textBold2}>
                        Aiutiamo i più giovani a diventare cittadini digitali responsabili
                    </h1>
                </div>
            </div>
            <div className={viviInternetStyle.infoContainer2} style={{marginTop:0}}>
                <h2 className={viviInternetStyle.textNormal}>
                    Il Web è un luogo straordinario dove accedere a informazioni,
                    sviluppare conoscenze ed entrare in contatto con persone in
                    tutto il mondo. Con "Vivi Internet, al meglio", vogliamo aiutare
                    i bambini, i ragazzi, i genitori e gli insegnanti a vivere il Web
                    responsabilmente.
                </h2>
                <h2 style={{marginTop: 0, marginBottom:0}}>
                    Seleziona una delle schede per maggiori informazioni.
                </h2>

            </div>
            <div className={viviInternetStyle.tabsContainer}>
                <div
                    className={viviInternetStyle.tab}
                    style={{backgroundColor: '#3f86f8'}}
                    onClick={() => navigateHandler('/basic-principles')}
                >
                    <h2 className={viviInternetStyle.tabText}>I principi Base</h2>
                </div>
                {/*<div
                    className={viviInternetStyle.tab}
                    style={{backgroundColor: '#3f86f8'}}
                    onClick={() => navigateHandler('/partner')}
                >
                    <h2 className={viviInternetStyle.tabText}>I nostri partner</h2>
                </div>*/}
                <div
                    className={viviInternetStyle.tab}
                    style={{backgroundColor: '#3f86f8'}}
                    onClick={() => navigateHandler('/projects')}
                >
                    <h2 className={viviInternetStyle.tabText}>Interland</h2>
                </div>
            </div>
            <div className={'img-free-container'}>

            </div>
        </div>

    )
}

export default ViviInternet;