import kidImage2 from "../../assets/kid-tel-2.jpeg";
import homeIcon from "../../assets/home-icon.svg";
import phoneImage from "../../assets/phone-3.png"

import {useNavigate} from "react-router-dom";
function Contact() {
    const navigate = useNavigate();

    function navigateHandler() {
        navigate('/family-link')
    }

    return (
        <div className={'main-panel'}>
            <img
                src={homeIcon}
                alt={'icon'}
                className={'img-home'}
                onClick={() => navigateHandler()}
            />
            <div className={'info-container'}>
                <div className={'txt-container'}>
                    <h1>Resta in contatto ovunque ti trovi</h1>
                    <h3>Ricevi aggiornamenti sulla durata della batteria</h3>
                    <p>
                        Non sempre è semplice comunicare mentre si è in giro.
                        Con Family Link puoi restare al corrente di tutto e
                        visualizzare la durata rimanente della batteria di un dispositivo.
                    </p>
                </div>
            </div>
            <div className={'img-container'}>
                <img src={kidImage2} alt={'icon'} className={'img-big'}/>
            </div>
            <img src={phoneImage} alt={'icon'} className={'img-phone-3'}/>
            <div className={'img-free-container'} style={{overflow:'hidden'}}>
                <svg className={'svg-line'} width="99vw" height="99vh">
                    <line fill="#3f86f8" stroke="#3f86f8" strokeWidth="10" x1="0vw" y1="35vh"
                          x2="12vw" y2="35vh"/>
                    <line fill="#fbbd02" stroke="#fbbd02" strokeWidth="10" x1="58vw" y1="0vh"
                          x2="58vw" y2="100vh"/>
                    <line fill="#32a754" stroke="#32a754" strokeWidth="10" x1="62vw" y1="88vh"
                          x2="85vw" y2="88vh"/>
                </svg>
            </div>
        </div>
    )
}

export default Contact;