import altroconsumoLogo from "../../assets/logos/logo-altroconsumo.svg";
import anteasLogo from "../../assets/logos/logo-anteas.png";
import fondazioneLogo from "../../assets/logos/logo-fondazione.png";
import generazioneLogo from "../../assets/logos/logo-generazione-connesse.png";
import poliziaLogo from "../../assets/logos/logo-polizia.png";

export const PARTNERS_DATA = [
    {
        content: 'La Polizia Postale e delle Comunicazioni, istituita con la legge di riforma dell’Amministrazione della Pubblica Sicurezza, rappresenta il settore specialistico della Polizia di Stato preposto a prevenire e contrastare la criminalità informatica. In questo settore nevralgico la Polizia Postale si inserisce con una incisiva azione di informazione e/o prevenzione volta ad eliminare i punti di debolezza della Rete, con particolare riguardo alla tutela dei minori online.',
        icon: poliziaLogo
    },
    {
        content: 'La Fondazione Mondo Digitale (FMD) è un\'organizzazione senza scopo di lucro fondata nel 2001, che lavora per una società della conoscenza inclusiva coniugando innovazione, istruzione, inclusione e valori fondamentali. La FMD offre lavoro accademico affiancato dallo sviluppo di strumenti e di progetti nell\'ambito dell\'istruzione, dell\'inclusione digitale e dello sviluppo territoriale e di comunità. Un aspetto strategico è la phirtualità, cioè l\'integrazione della dimensione fisica con quella virtuale in tutti i processi di innovazione promossi.',
        icon: fondazioneLogo
    },
    {
        content: 'Con oltre 345.000 soci, Altroconsumo è la più grande organizzazione di consumatori italiana. Fornire piccole e grandi risposte quotidiane a portata di mano è il suo impegno nei confronti di tutti coloro che vogliono fare scelte più consapevoli ed efficaci. Mette a disposizione dei cittadini indipendenza, scientificità, senso critico e la volontà di difendere sempre i loro diritti. In un mondo pieno di domande, Altroconsumo dà risposte.',
        icon: altroconsumoLogo
    },
    {
        content: 'Associazione Nazionale Tutte le Età Attive per la Solidarietà, nasce nel 1996 traendo spunto da diverse esperienze locali promosse e sostenute dalla Federazione Nazionale Pensionati CISL. Oggi è presente su tutto il territorio nazionale con circa 500 associazioni di volontariato e di promozione sociale e conta oltre 80.000 soci aderenti. Promossa originariamente dagli anziani e a questi prevalentemente rivolta, ai giorni nostri l’associazione amplia il proprio pubblico di riferimento e si rivolge a tutti coloro che condividono l’impegno della solidarietà civile e sociale.',
        icon: anteasLogo
    },
    {
        content: 'Generazioni Connesse è il Safer Internet Centre Italiano cofinanziato dalla Commissione Europea, coordinato dal Ministero dell\'Istruzione, in partnership con istituzioni e organizzazioni pubbliche e private al fine di supportare la scuola, i genitori, gli alunni e in generale tutti i giovani nell\'approfondire le tematiche relative alla sicurezza in Rete, all\'uso consapevole di Internet e al rapporto tra i giovani e i nuovi media.',
        icon: generazioneLogo
    },
]